import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { kebabCase, isString } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import Chip from '@material-ui/core/Chip'
import { Carousel } from 'react-responsive-carousel'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  useTheme,
  darken,
  lighten,
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CustomPageContext from '../Context/CustomPageContext'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import MetaTags from '../components/MetaTags'
import appConfig from '../config/config'
import '../styles/carousel.min.css'

// Imported using loadble as it has window dependency (to handle SSR)
const ModelViewer = loadable(() => import('../components/ModelViewer'))

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  section: {
    padding: '1.2rem 0rem',
  },
  titleContainer: {},
  title: {
    fontSize: '2.2rem',
  },
  descriptionContainer: {
    padding: '0.75rem 0',
  },
  description: {
    fontSize: '1.2rem',
  },
  carouselContainer: {
    borderBottom: `12px solid ${theme.palette.divider}`,
  },
  carouselCaption: {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    whiteSpace: 'nowrap',
  },
  '@global': {
    '[class*="gatsby-resp-image-image"]': {
      boxShadow: 'none !important',
    },
  },
  postContent: {
    marginTop: '1rem',
    fontSize: '1.15rem',
    overflowWrap: 'break-word',
    '& > *': {
      margin: '0rem 0.5rem',
      marginBottom: '1rem',
    },
    '& > h1, h2, h3, h4, h5, h6': {
      marginTop: '2rem',
    },
    '& a': {
      color: '#0095ff',
    },
    '& a:visited': {
      color: darken('#0095ff', 0.3),
    },
    '& a:active': {
      color: lighten('#0095ff', 0.5),
    },
    '& .table-of-contents': {
      fontSize: '1rem',
      '& a:visited, a': {
        color: 'unset',
      },
      '& a:active': {
        color: 'rgb(187, 187, 187)',
      },
    },
    // '& > p > img, figure': {
    //   margin: '0rem -0.5rem',
    //   marginBottom: '-0.5rem',
    //   width: 'calc(100% + 1rem)', // +1rem to account for the margin 0.5rem on both sides
    // },
    // lineHeight: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      // fontSize: '1.15rem',
      '& > *': {
        margin: '0rem 5rem',
        marginBottom: '1rem',
      },
      // '& > p > img, figure': {
      //   margin: '0rem -5rem',
      //   marginBottom: '-0.5rem',
      //   width: 'calc(100% + 10rem)', // +1rem to account for the margin 0.5rem on both sides
      // },
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: '1.15rem',
    },
  },
  tagsContainer: {
    marginTop: '2rem',
  },
}))

export const CreationsPostTemplate = ({
  location,
  content,
  contentComponent,
  description,
  tags,
  title,
  show3dViewer,
  model3DList,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  const theme = useTheme()
  const classes = useStyles()
  const [displayModelIndex, setDisplayModelIndex] = useState(0)

  return (
    <section className={classes.section}>
      {helmet || ''}
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          xs={11}
          sm={10}
          md={9}
          lg={7}
          xl={5}
          style={{ width: '100%' }}
        >
          <div className={classes.titleContainer}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </div>
          <div className={classes.descriptionContainer}>
            <Typography color="textSecondary" className={classes.description}>
              {description}
            </Typography>
          </div>
          {show3dViewer && model3DList && model3DList.length ? (
            <div className={classes.carouselContainer}>
              <Carousel
                autoPlay={false}
                showArrows
                swipeable={false}
                showThumbs={false}
                onChange={(index, item) => setDisplayModelIndex(index)}
              >
                {model3DList.map((item, index) => {
                  if (index === displayModelIndex) {
                    return (
                      <div
                        key={`${item.model3d.publicURL}-${item.model3d.name}`}
                      >
                        <ModelViewer
                          modelSrc={item.model3d.publicURL}
                          dummy={!item.model3d.publicURL}
                        />
                        {item.caption && (
                          <span className={classes.carouselCaption}>
                            <Chip
                              style={{ opacity: 0.7 }}
                              size="small"
                              label={item.caption}
                            />
                          </span>
                        )}
                      </div>
                    )
                  }
                  return (
                    <span
                      key={`${item.model3d.publicURL}-${item.model3d.name}`}
                    />
                  )
                })}
              </Carousel>
            </div>
          ) : null}
          <PostContent content={content} className={classes.postContent} />
          {tags && tags.length ? (
            <div className={classes.tagsContainer}>
              <Grid container spacing={1}>
                {tags.map((tag) => (
                  <Grid item key={`${tag}tag`}>
                    <Link
                      to={`/tags/${kebabCase(tag)}/`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Chip
                        size="small"
                        avatar={<LocalOfferIcon style={{ width: 15 }} />}
                        label={tag}
                        clickable
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </section>
  )
}

CreationsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  location: PropTypes.object,
  show3dViewer: PropTypes.bool,
  model3DList: PropTypes.array,
  tags: PropTypes.array,
}

const CreationsPost = ({ data, location }) => {
  const { pathname } = location
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const { markdownRemark: post, site } = data
  const [uiTheme, setUiTheme] = useState(
    initUiTheme || appConfig.defaultInitTheme
  )

  const postThumbnailImageObj = // Get Thumnail Img Object
    post &&
    post.frontmatter &&
    post.frontmatter.featuredImage &&
    post.frontmatter.featuredImage.childImageSharp &&
    post.frontmatter.featuredImage.childImageSharp.fluid

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#212121' },
          secondary: { main: '#DD2C00' },
        },
      }),
    [uiTheme]
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <CreationsPostTemplate
            content={post.html}
            contentComponent={HTMLContent}
            description={post.frontmatter.description}
            helmet={
              <>
                <MetaTags
                  pageTitle={post.frontmatter.title}
                  pageDescription={post.frontmatter.description}
                  pageThumbnailImage={
                    postThumbnailImageObj && {
                      src: postThumbnailImageObj.src,
                      width: postThumbnailImageObj.presentationWidth,
                      height: postThumbnailImageObj.presentationHeight,
                    }
                  }
                />
              </>
            }
            tags={post.frontmatter.tags}
            title={post.frontmatter.title}
            show3dViewer={post.frontmatter.show3dViewer}
            model3DList={post.frontmatter.model3DList}
          />
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

CreationsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.node,
      frontmatter: PropTypes.shape({
        date: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        featuredImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        show3dViewer: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.string),
        model3DList: PropTypes.arrayOf(
          PropTypes.shape({
            model3d: PropTypes.shape({
              name: PropTypes.string,
              publicURL: PropTypes.string,
            }),
            caption: PropTypes.string,
          })
        ),
      }),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        contentTitle: PropTypes.string,
        siteUrl: PropTypes.string,
      }),
    }),
  }),
  location: PropTypes.object,
}

export default CreationsPost

export const pageQuery = graphql`
  query CreationsPostByID($id: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 50) {
              presentationWidth
              presentationHeight
              ...GatsbyImageSharpFluid
            }
          }
        }
        show3dViewer
        tags
        model3DList {
          model3d {
            name
            publicURL
          }
          caption
        }
      }
    }
  }
`
